const timeUtil = {
  isToday(date) {
    const today = new Date();
    // Not the simplest way, but is fast; https://stackoverflow.com/a/17861971/529171
    return this.isSameDate(date, today);
  },

  isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return this.isSameDate(date, yesterday);
  },

  isSameDate(date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  },

  createDateTimeString(date) {
    return (
      date.getFullYear() +
      "-" +
      this.getNumberAsTwoDigits(date.getMonth() + 1) +
      "-" +
      this.getNumberAsTwoDigits(date.getDate()) +
      " " +
      this.getNumberAsTwoDigits(date.getHours()) +
      ":" +
      this.getNumberAsTwoDigits(date.getMinutes())
    );
  },

  getDateRangeFromMonths(fromYear, fromMonth, toYear, toMonth) {
    fromYear = parseInt(fromYear, 10);
    fromMonth = parseInt(fromMonth, 10);
    toYear = parseInt(toYear, 10);
    toMonth = parseInt(toMonth, 10);
    if (toMonth === 12) {
      toMonth = 1;
      toYear++;
    } else {
      toMonth++;
    }
    var startDay = "-01 00:00";

    return {
      from: fromYear + "-" + this.getNumberAsTwoDigits(fromMonth) + startDay,
      to: toYear + "-" + this.getNumberAsTwoDigits(toMonth) + startDay
    };
  },

  getNumberAsTwoDigits(monthNum) {
    if (monthNum < 10) {
      monthNum = "0" + monthNum;
    }
    return monthNum.toString();
  }
};

export default timeUtil;
