<template>
  <CustomBtn
    icon="mdi-refresh"
    :text="$t('update')"
    v-on:click="onClick"
    :block="block"
  />
</template>

<script>
import CustomBtn from "@/components/misc/CustomBtn";
export default {
  name: "UpdateBtn",
  components: { CustomBtn },
  props: {
    block: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>
