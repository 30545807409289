<template>
  <div>
    <v-form autocomplete="off" v-model="formValid" v-if="template">
      <v-card>
        <v-card-title>
          {{ template.name }}
        </v-card-title>
        <v-card-text>
          <div v-if="template">
            <v-text-field
              v-model.trim="template.name"
              :rules="validationRules.requiredRule"
              outlined
              :label="$t('templateName')"
              autofocus
              maxlength="50"
              data-testid="nameAlertTemplateDetails"
            />

            <DepartmentSelect
              :init-department="selectedDepartment"
              :dense="true"
              v-on:change="setDepartment"
              data-testid="departmentSelectAlertTemplateDetails"
            />

            <SmsComposer
              :init-enabled="useSms"
              :init-text="template.text.sms"
              :hide-sms-sender="true"
              v-on:change="setSms"
              data-testid="smsComposerAlertTemplateDetails"
            />

            <EmailComposer
              :init-enabled="useEmail"
              :init-title="template.emailTitle"
              :init-text="template.text.email"
              :hide-attachment-feature="true"
              v-on:change="setEmail"
              data-testid="emailComposerAlertTemplateDetails"
            />

            <VoiceComposer
              :init-enabled="useVoice"
              :init-voice-props="template.voiceSettings.voiceProps"
              :init-text="template.text.voice"
              :init-attempts="template.voiceSettings.attempts"
              :init-delay="template.voiceSettings.delay"
              :init-deputy="template.voiceSettings.deputy"
              v-on:change="setVoice"
              data-testid="voiceComposerAlertTemplateDetails"
            />

            <v-text-field
              class="mt-6"
              maxlength="50"
              v-model.trim="template.alertName"
              outlined
              :label="alertNameLabel"
              data-testid="alertNameAlertTemplateDetails"
            />

            <br />
            <v-card class="res-opt">
              <v-card-title>
                {{ $t("responseOptions") }}
              </v-card-title>
              <v-card-text>
                <div v-for="res in template.responseOptions">
                  <ResponseChip :text="res.text" :number="res.number" />
                </div>
                <div v-if="!atLeastOneResponseOptionIsSet()">
                  {{ $t("none") }}
                </div>
                <v-dialog
                  v-model="dialogResponse"
                  width="500"
                  scrollable
                  persistent
                >
                  <ResponseOptionsComposer
                    :responses="responsCreatorResponses"
                    v-on:done="setResponses"
                  />
                </v-dialog>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  @click="showResponseDialog"
                  data-testid="editResponseOptionsAlertTemplateDetails"
                >
                  {{ $t("edit") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions v-if="template">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" xs="6">
                <v-btn
                  v-if="template.id"
                  class="error mt-4"
                  @click="dialogDelete = true"
                  small
                  data-testid="deleteAlertTemplateDetails"
                  :block="$vuetify.breakpoint.xs"
                >
                  {{ $t("deleteTemplate") }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" xs="6" align="right">
                <v-spacer />
                <v-btn
                  @click="emitClose"
                  :block="$vuetify.breakpoint.xs"
                  class="mt-2"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <span v-if="!$vuetify.breakpoint.xs" class="ml-2" />
                <v-btn
                  class="primary mt-2"
                  @click="save"
                  :disabled="!formValid || !smsLengthValid || !smsTextValid"
                  data-testid="SaveAlertTemplateDetails"
                  :block="$vuetify.breakpoint.xs"
                >
                  {{ $t("save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="dialogDelete" max-width="550">
      <UserConfirm
        :text="$t('deleteAlertTempl?')"
        v-on:cancel="dialogDelete = false"
        v-on:ok="deleteTemplate"
      />
    </v-dialog>
    <!-- Just to please IntellJ IDE inspection -->
    <span style="display: none" class="channel-panel"></span>
  </div>
</template>

<script>
import configVoice from "@/config/configVoice";
import apiAlertTemplates from "@/api/v24/apiAlertTemplates";
import store from "@/store";
import formValidationRules from "@/util/formValidationRules";
import VoiceComposer from "@/components/composers/VoiceComposer";
import EmailComposer from "@/components/composers/EmailComposer";
import SmsComposer from "@/components/composers/SmsComposer";
import UserConfirm from "@/components/misc/UserConfirm";
import ResponseChip from "@/components/alert/ResponseChip";
import ResponseOptionsComposer from "@/components/alert/ResponseOptionsComposer";
import ResponsePreview from "@/components/misc/ResponsePreview";
import objectUtil from "@/util/objectUtil";
import alertResponseOptions from "@/util/alertResponseOptions";
import user from "@/util/user";
import DepartmentSelect from "@/components/departments/DepartmentSelect";

export default {
  name: "AlertTemplateDetails",
  components: {
    DepartmentSelect,
    VoiceComposer,
    EmailComposer,
    SmsComposer,
    UserConfirm,
    ResponseChip,
    ResponseOptionsComposer,
    ResponsePreview
  },

  props: {
    inTemplate: {
      type: Object,
      required: true
    }
  },

  computed: {
    alertNameLabel() {
      return (
        this.$t("alertName") + " (" + this.$t("optional").toLowerCase() + ")"
      );
    },
    userIsAdmin() {
      return store.getters.getUserIsAdmin;
    }
  },

  data: () => ({
    template: null,
    dialogResponse: false,
    responsCreatorResponses: null,
    requestedTemplate: null,
    formValid: false,
    validationRules: formValidationRules,
    smsLengthValid: true,
    smsTextValid: true,
    orgTemplate: null,
    useSms: false,
    useEmail: false,
    useVoice: false,
    dialogDelete: false,
    selectedDepartment: ""
  }),

  watch: {
    inTemplate: {
      immediate: true,
      handler() {
        this.template = objectUtil.deepClone(this.inTemplate);
        this.responsCreatorResponses = alertResponseOptions.createResponseOptArrayFromTemplate(
          this.template
        );
        if (this.template.department) {
          this.selectedDepartment = this.template.department;
        } else {
          user.getDepartmentForItemSave().then(department => {
            this.selectedDepartment = department;
          });
        }

        this.useSms = !!this.template.text.sms;
        this.useEmail = !!this.template.text.email;
        this.useVoice = !!this.template.text.voice;
      }
    }
  },

  methods: {
    showResponseDialog() {
      this.dialogResponse = true;
    },
    setSms(smsSettings) {
      this.useSms = smsSettings.enabled;
      this.template.text.sms = this.useSms ? smsSettings.text : "";
      this.smsLengthValid = smsSettings.lengthValid;
      this.smsTextValid = smsSettings.textValid;
    },
    setEmail(emailSettings) {
      this.useEmail = emailSettings.enabled;
      this.template.text.email = this.useEmail ? emailSettings.text : "";
      this.template.emailTitle = this.useEmail ? emailSettings.title : "";
    },
    setVoice(voiceSettings) {
      this.useVoice = voiceSettings.enabled;
      let vs = this.template.voiceSettings;
      if (this.useVoice) {
        this.template.text.voice = voiceSettings.text;
        vs.attempts = voiceSettings.attempts;
        vs.delay = voiceSettings.delay;
        vs.deputy = voiceSettings.deputy;
        vs.endOnState = voiceSettings.endOnState;
        vs.voiceProps = voiceSettings.voiceProps;
      } else {
        this.template.text.voice = "";
        vs.attempts = configVoice.defaultSetting.attempts;
        vs.delay = configVoice.defaultSetting.delay;
        vs.deputy = configVoice.defaultSetting.deputy;
        vs.endOnState = configVoice.defaultSetting.endOnState;
        vs.voiceProps = null;
      }
    },
    atLeastOneResponseOptionIsSet() {
      let i = 0;
      const len = this.template.responseOptions.length;
      for (i; i < len; i++) {
        if (this.template.responseOptions[i].text) {
          return true;
        }
      }
      return false;
    },
    setResponses(responseOpts) {
      this.responsCreatorResponses = responseOpts;
      const updatedTemplateResponses = [];
      responseOpts.forEach(rcr => {
        updatedTemplateResponses.push({
          number: rcr.num,
          text: rcr.value ? rcr.value : rcr.customValue,
          link: rcr.link
        });
      });
      this.template.responseOptions = updatedTemplateResponses;
      this.dialogResponse = false;
    },
    setDepartment(departmentId) {
      this.selectedDepartment = departmentId;
    },
    deleteTemplate() {
      apiAlertTemplates.delete(this.template).then(() => {
        this.dialogDelete = false;
        store.dispatch("updateAlertTemplates");
        store.commit("setAppSnackbar", this.$t("alertTemplateDeleted"));
        this.emitClose();
      });
    },
    save() {
      let promise;
      if (!this.template.id) {
        promise = apiAlertTemplates.create(
          this.template,
          this.selectedDepartment
        );
      } else {
        promise = apiAlertTemplates.update(
          this.template,
          this.selectedDepartment
        );
      }
      promise.then(() => {
        store.dispatch("updateAlertTemplates");
        store.commit("setAppSnackbar", this.$t("alertTemplateSaved"));
        this.emitClose();
      });
    },
    emitClose() {
      this.template = null;
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
.res-opt {
  max-width: 500px;
}
.channel-panel {
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
}
</style>
