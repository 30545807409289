<template>
  <v-container>
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <v-card id="card">
          <v-card-title>
            {{ $t("alertTemplates") }}
          </v-card-title>
          <v-card-text>
            <v-col cols="12" class="text-end">
              <v-btn @click="setEmptyTemplate" data-testid="newAlertTemplate">
                <v-icon left>mdi-plus</v-icon>
                {{ $t("newTemplate") }}
              </v-btn>
            </v-col>
            <v-text-field
              :label="$t('search')"
              v-model="search"
              data-testid="searchAlertTemplates"
            />
            <DataTable
              :items="templates"
              :headers="listHeaders"
              :search="search"
              :custom-filter="filterList"
            >
              <template v-slot:item.actions="{ item }">
                <div class="text-end">
                  <v-btn outlined @click="setTemplate(item)">
                    <v-icon left small>
                      mdi-eye
                    </v-icon>
                    {{ $t("vis") }}
                  </v-btn>
                </div>
              </template>
            </DataTable>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" sm="12">
        <v-dialog
          v-model="dialogDetails"
          v-if="template"
          persistent
          :max-width="maxDialogWidth"
          maxlength="50"
        >
          <AlertTemplateDetails
            :in-template="template"
            v-on:close="toggleDetails(false)"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import configVoice from "@/config/configVoice";
import AlertTemplateDetails from "@/components/alertTemplates/AlertTemplateDetails";
import AlertTemplate from "@/util/AlertTemplate";
import config from "@/config/config";
import search from "@/util/search";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "AlertTemplates",
  components: { DataTable, AlertTemplateDetails },
  computed: {
    listHeaders() {
      let headers = [{ text: this.$t("name"), value: "name" }];
      if (store.getters.getShowDepartmentInfoInLists) {
        headers.push({
          text: this.$t("department"),
          value: "departmentName"
        });
      }
      headers.push({ text: "", value: "actions" });
      return headers;
    },
    templates() {
      return store.state.alertTemplates;
    }
  },

  data: () => ({
    search: "",
    template: null,
    dialogDetails: false,
    maxDialogWidth: config.dialogWith.xLarge,
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    }
  }),

  created() {
    store.dispatch("updateAlertTemplates");
  },

  methods: {
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    setTemplate(template) {
      const __setTemplate = template => {
        this.template = template;
        this.toggleDetails(true);
      };
      this.template = null;
      if (template.voiceSettings.voiceProps) {
        __setTemplate(template);
      } else {
        configVoice.getDefaultProps().then(props => {
          template.voiceSettings.voiceProps = props;
          this.template = template;
          __setTemplate(template);
        });
      }
    },
    setEmptyTemplate() {
      const emptyTemplate = new AlertTemplate();
      this.setTemplate(emptyTemplate.get());
    },
    toggleDetails(state) {
      this.dialogDetails = state;
      if (!state) {
        this.template = null;
      }
    }
  }
};
</script>

<style scoped>
#card {
  max-width: 900px;
}
</style>
